export const getCenterCoordinates = () => {
  const center: any = {
    lat: 34.8818796,
    lng: 136.5842092
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 14
  return zoom
}
